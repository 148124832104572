import { createRouter , createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component:() => import('../views/home/homeIndex.vue'),
    meta: {
      title: '导航',
    }
  },
  {
    path: '/home',
    name: 'home',
    component:() => import('../views/home/homeIndex.vue'),
    meta: {
      title: '导航',
    }
  },
  {
    path: '/login',
    name: 'login',
    component:() => import('../views/login/loginIndex.vue'),
    meta: {
      title: '登录',
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component:() => import('../views/settings/settingsIndex.vue'),
    meta: {
      title: '设置',
    }
  },
  {
    path: '/register',
    name: 'register',
    component:() => import('../views/register/registerIndex.vue'),
    meta: {
      title: '注册',
    }
  },
  {
    path: '/task',
    name: 'task',
    component:() => import('../views/task/taskIndex.vue'),
    meta: {
      title: '任务管理',
    }
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component:() => import('../views/forgotPassword/forgotPassword.vue'),
    meta: {
      title: '忘记密码',
    }
  },
  {
    path: '/v2',
    name: 'v2',
    component:() => import('../views/homeV2/homeV2.vue'),
    meta: {
      title: '主页',
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
